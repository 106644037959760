import React, { useContext, memo } from 'react';
import { Helmet } from 'react-helmet';
import Loadable from 'react-loadable';
import { injectIntl } from 'gatsby-plugin-intl';
import '../assets/home.scss';
import reduce from 'lodash/reduce';
import ApplicationContext from '../state/ApplicationContext';
import LotteriesContext from '../state/LotteriesContext';
import { TopLotteries } from '../components/pages/Home/TopLotteries';
import TopBlockPlaceholder from '../components/pages/Home/TopBlockPlaceholder';
import TopBlock from '../components/pages/Home/TopBlock'
import OtherLotteris from '../components/pages/Home/OtherLotteries'
import LotteriesResults from '../components/pages/Home/LotteriesResults'


interface IGame {
  logoImgUrl: string;
  jackpot: number;
  nextDrawTime: string;
  slug: string;
}

// const OtherLotteris = Loadable({
//   // eslint-disable-next-line @typescript-eslint/no-unsafe-call
//   loader: () => import('../components/pages/Home/OtherLotteries'),
//   loading: () => <p />,
// });
const HowItWork = Loadable({
  loader: () => import('../components/pages/Home/HowItWork'),
  loading: () => <p />,
});
const Testimonials = Loadable({
  loader: () => import('../components/pages/Home/Testimonials'),
  loading: () => null,
});
const Roopie = Loadable({
  loader: () => import('../components/pages/Home/Roopie'),
  loading: () => <p />,
});
const WeGiveBack = Loadable({
  loader: () => import('../components/pages/Home/WeGiveBack'),
  loading: () => <p />,
});
// const LotteriesResults = Loadable({
//   loader: () => import('../components/pages/Home/LotteriesResults'),
//   loading: () => null,
// });

// const TopBlock = Loadable({
//   loader: () => import('../components/pages/Home/TopBlock'),
//   loading: () => <TopBlockPlaceholder />,
// });
/**
 * Main homepage container
 * @returns {React.Component}
 */
const Home = ({ intl: { formatMessage } }) => {
  const { isDesktop } = useContext(ApplicationContext);
  const { lotteries, draws } = useContext(LotteriesContext);
  const topBlock: IGame = reduce(lotteries, (acc, gameEntity) => (!acc || acc.jackpot < gameEntity.jackpot ? gameEntity : acc), null);
  return (
    <div>
      <Helmet>
        <title>
          Lotteroo | {formatMessage({ id: 'Opening global lottery jackpots for everyone' })}
        </title>
      </Helmet>
      <TopBlock topBlock={topBlock} isDesktop={isDesktop} />
      <div className="page-content">
        <TopLotteries lotteries={lotteries} isDesktop={isDesktop} />
        <div className="lotteries-wrap">
          <div className={'isDesktopShow'}>
            <LotteriesResults draws={draws} />
          </div>
          <OtherLotteris lotteries={lotteries} />
        </div>
      </div>

      {isDesktop && <HowItWork />}
      <Roopie />

      {isDesktop && <Testimonials />}
      <WeGiveBack />
    </div>
  );
};

export default memo(injectIntl(Home));
