import React, { useState, memo } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import { useInView } from 'react-intersection-observer';
import { transformPrice } from '../../../utils/formattes';
import clock from '../../../assets/icons/clock.svg';
import clockWhite from '../../../assets/icons/clock-white.svg';
import { transform } from '../../../utils/dateFormatted';
import './top-block.scss';

const TopBlock = ({ isDesktop, topBlock, intl: { formatMessage, locale } }) => {
  const [currentInView, setCurrentInView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0,
  });
  if (inView && !currentInView) {
    setCurrentInView(true);
  }

  const currentLocale = locale === 'en' ? '': `/${locale}`

  return (
    <div
      ref={ref}
      className={classNames('top-block desktop', {
        mobile: !isDesktop,
      })}
    >
      <div className="top-block-gradient" />
      <div className="game-block-wrap">
        <div className="game-block">
          <div className="logo">
            {topBlock?.logoImgUrl ? <img src={currentInView ? topBlock?.logoImgUrl : null} alt="Mega millions" /> : (
              <span
                className="placeholder-data"
                style={{
                  height: 40,
                  width: '100%',
                }}
              />
            )}
          </div>
          <div className="text">
            {formatMessage({ id: 'PLAY THE WORLD’S BIGGEST LOTTERIES…' })}
          </div>
          <div className="stake">
            {topBlock ? transformPrice(topBlock?.jackpot, 'amountForHotGame') : (
              <span
                className="placeholder-data"
                style={{
                  height: 50,
                  width: '100%',
                }}
              />
            )}
          </div>
          <div className="next-draw">
            <img src={isDesktop ? clock : clockWhite} alt="draw" />
            <div className="parent-text">
              <div>
                {' '}
                {formatMessage({ id: 'Next draw:' })}
                {' '}
              </div>
              <div className="time-left">{topBlock?.nextDrawTime ? transform(topBlock?.nextDrawTime, 'forHomePage') : <span className={'placeholder-data'} style={{
                width: 128,
                height: 16
              }}></span>}</div>
            </div>
          </div>
          <div className="button">
            <Link to={`${currentLocale}/play/${topBlock?.slug}`} className="play-now-btn green-btn">
              {formatMessage({ id: 'Play now ({price})' }, {
                price: transformPrice(topBlock?.prices[0].pricePerLine, 'amountWithCurrency'),
              })}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(injectIntl(TopBlock));
