import React, { memo } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import classNames from 'classnames';
import GameItem from '../../Cards/GameItem';


interface OtherLotteriesProps {
  lotteries: any[];
  intl: any;
}
const emptyArray = [null, null, null, null, null, null, null, null, null].map((element, index) => ({ value: null, id: index }));
const cols = 3;
const OtherLotteris = ({ lotteries, intl: { formatMessage } }: OtherLotteriesProps) => (
  <div className="other-lotteries">
    <div className="title">{formatMessage({ id: 'Other Lotteries' })}</div>
    <ul>
      {lotteries ? lotteries.map((element: any, i: number) => (
        <li
          key={element.id}
          className={classNames({
            'clear-border-top': i < cols,
            'clear-border-left': i % cols === 0,
            'clear-border-right': i % cols === cols - 1,
          })}
        >
          <GameItem gameData={element} column />
        </li>
      )) : (
        <>
          {emptyArray.map((element, i) => (
            <li
              key={element.id}
              className={classNames({
                'clear-border-top': i < cols,
                'clear-border-left': i % cols === 0,
                'clear-border-right': i % cols === cols - 1,
              })}
            >
              <GameItem gameData={element.value} column />
            </li>
          ))}
        </>
      )}
    </ul>
  </div>
);

export default injectIntl(memo(OtherLotteris));
