import React from 'react';
import './game-card.scss';
import { Link } from 'gatsby';
import clock from '../../../assets/icons/clock.svg';
import {injectIntl} from "gatsby-plugin-intl"
import { transformPrice } from '../../../utils/formattes';
import { transform } from '../../../utils/dateFormatted';

const GameCard = ({ game, intl: { formatMessage, locale } }) => {
  const currentLocale = locale === 'en' ? '': `/${locale}`
  return (
  <div className="game-card">
    <div
      className="game-card-top-block"
      style={{
        backgroundImage: `url(${game?.backgroundImgUrl})`,
      }}
    >
      <div className="header-text">
        <h2>{game?.lotteryTitle}</h2>
        <div className="amount-text">{transformPrice(game?.jackpot, 'amountWithCurrency')}</div>
      </div>

    </div>
    <div className="game-card-bottom-block">
      <div className="next-draw">
        <div className="img">
          <img src={clock} />
        </div>
        <div>
          <div>{formatMessage({id: 'Next draw:'})}</div>
          <div className="time-left">{transform(game?.nextDrawTime, 'forHomePage')}</div>
        </div>
      </div>
      <div className="button">
        <Link className="play-now-link green-btn" to={`${currentLocale}/play/${game.slug}`}>
          {formatMessage({id: 'Play Now'})}
        </Link>
      </div>
    </div>
  </div>
)};

export default injectIntl(GameCard);
