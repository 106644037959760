import React, {memo} from 'react';
import { Link } from 'gatsby';
import {injectIntl} from "gatsby-plugin-intl"
import DrawItem from '../../Cards/DrawItem';

const LotteriesResults = ({ draws, intl: { formatMessage, locale } }) => {
  const currentLocale = locale === 'en' ? '': `/${locale}`
  return (
  <div className="lotteries-results">
    <div className="header-block">
      <div className="title">{formatMessage({id: 'Lottery Results'})} </div>
      <Link to={`${currentLocale}/results`} className="view-all-link">{formatMessage({id: 'View all'})}</Link>
    </div>
    <ul className="results-list-wrap">
      <li className="result-wrap">
        {draws ? draws.map((element) => (
          <DrawItem
            key={element.id}
            isHome={true}
            resultData={{
            title: element.lotteryTitle,
            name: element.slug,
            drawDate: element.drawDate,
            mainNumbers: element.mainNumbers,
            bonusNumbers: element.bonusNumbers,
            src: element.logoImgUrl,
          }}
          />
        )) : (
          <>
            <DrawItem resultData={{}} />
            <DrawItem resultData={{}} />
            <DrawItem resultData={{}} />
            <DrawItem resultData={{}} />
          </>
        )}
      </li>
      <li className="old-link-item">
        <Link to={`${currentLocale}/results`} className="view-older-link">
          {formatMessage({id: 'View Older Results'})}
        </Link>
      </li>
    </ul>
  </div>
)};

export default memo(injectIntl(LotteriesResults));
