// @flow
import React, { useState, memo } from 'react';
import type { Node } from 'react';
import './game-item.scss';
import { Link } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import clock from '../../../assets/icons/clock.svg';
import { transformPrice } from '../../../utils/formattes';
import { transform } from '../../../utils/dateFormatted';

type gameDataType = {
  name: string,
  logo: string,
  amount: string
};
type Props = {
  column: boolean,
  gameData: gameDataType
};

const GameItem = ({ column, gameData, intl: {locale, formatMessage} }: Props): Node => {
  const [currentInView, setCurrentInView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0,
  });
  if (inView && !currentInView) {
    setCurrentInView(true);
  }
  const translate = (str) => (formatMessage({id: str}))

  const currentLocale = locale === 'en' ? '': `/${locale}`
  return (
    <div
      className={classNames('game-item', {
        column,
      })}
      ref={ref}
    >
      <div className="top">
        <div className="logo">
          {gameData && gameData.ballImgUrl ? <img src={currentInView ? gameData.ballImgUrl : undefined} alt={gameData.lotteryTitle} /> : (
            <span
              className="placeholder-data top-lotteries-placeholder-data"
            />
          )}
        </div>
        <div className="info">
          <div className="label">
            {gameData ? gameData.lotteryTitle : (
              <span
                className="placeholder-data"
                style={{
                  width: '100%',
                  height: 20,
                }}
              />
            )}
          </div>
          <div
            className="amount"
          >
            {transformPrice(gameData?.jackpot, 'amountWithCurrency')}
          </div>
        </div>
      </div>
      <div className="bottom">
        {!column && (
        <div className="next-draw">
          <div className="img">
            <img src={clock} alt="wraw" />
          </div>
          <div>
            <div>{translate('Next draw')}</div>
            <div className="time-left">{gameData && gameData?.nextDrawTime ? transform(gameData?.nextDrawTime, 'forHomePage') : <span className={'placeholder-data placeholder-data-time'}></span>}</div>
          </div>
        </div>
        )}
        <div className="button">
          <Link to={`${currentLocale}/play/${gameData?.slug}`} className="play-now-link">
             {translate('Play Now')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(injectIntl(GameItem));
