import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import {injectIntl} from "gatsby-plugin-intl"
import left from '../../../assets/icons/arrow-left-icon.svg';
import right from '../../../assets/icons/arrow-right-icon.svg';
import 'swiper/css/swiper.css';
import GameCard from '../../Cards/GameCard';
import GameItem from '../../Cards/GameItem';
import { useInView } from 'react-intersection-observer';
import './top-lotteries.scss';

const params = {
  slidesPerView: 3,
  spaceBetween: 28,
  preventClicks: false,
  preventClicksPropagation: false,
  loop: true,
  observer: true,
};

const TopLotteries = injectIntl(({ lotteries, isDesktop, intl: { formatMessage, locale } }) => {
  const [swiper, updateSwiper] = useState(null);
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const [currentInView, setCurrentInView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0,
  });
  if (inView && !currentInView) {
    setCurrentInView(true);
  }
  return (
    <>
      {isDesktop && (
      <div className="top-lotteries desktop" ref={ref}>
        {currentInView ? (
          <>
            <img
              className="arrow-left"
              src={currentInView ? left : null}
              alt="left arrow"
              onClick={goPrev}
            />
            <img
              className="arrow-right"
              src={currentInView ? right : null}
              alt="right arrow"
              onClick={goNext}
            />
          </>
        ) : null}

        {lotteries?.length ? (
          <Swiper {...params} getSwiper={updateSwiper} shouldSwiperUpdate>
            {lotteries?.map((element) => (
              <div
                key={element.urlName}
                className={'top-lotteries-game-wrapper'}
              >
                <GameCard game={element} />
              </div>
            ))}
          </Swiper>
        ) : (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          >
            <div style={{
              width: '32%',
            }}
            >
              <GameCard game={{}} />
            </div>
            <div style={{
              width: '32%',
            }}
            >
              <GameCard game={{}} />
            </div>
            <div style={{
              width: '32%',
            }}
            >
              <GameCard game={{}} />
            </div>
          </div>
        )}
      </div>
      )}

      <div className="top-lotteries mobile">
        <div
          className="title"
        >
          {formatMessage({id: "Top Lotteries"})}
        </div>
        <ul>
          {lotteries ? lotteries?.map((element) => (
            <li>
              <GameItem gameData={element} key={element.id}/>
            </li>
          )) : (
            <>
              <li>
                <GameItem gameData={{}} />
              </li>
              <li>
                <GameItem gameData={{}} />
              </li>
              <li>
                <GameItem gameData={{}} />
              </li>
              <li>
                <GameItem gameData={{}} />
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
});

export { TopLotteries };
